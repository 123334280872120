<template>
  <CCard class="px-2">
    <CCardHeader>
      <div class="h5 text-center">입고 내역 신고</div>
    </CCardHeader>
    <CCardBody>
      <el-form label-width="30%" v-if="is_ready && factory" class="my-4">
        <el-form-item label="공장">
          <el-input class="w-100" v-model="factory.name" readonly />
        </el-form-item>
        <el-form-item label="차번4자리">
          <el-select class="w-100" v-model="event_id" placeholder="차량 뒷번호를 선택하십시오" @change="onEvent">
            <el-option v-for="(item,index) in event_list" :label="item.lp_4" :value="item.id" :key="`lp-opts-${index}`" />
          </el-select>
        </el-form-item>
        <div v-if="event">
          <el-form-item>
            <img :src="event.image_1" class="w-100" />
          </el-form-item>
          <el-form-item>
            <img :src="event.image_2" class="w-100" />
          </el-form-item>
          <el-form-item label="차량번호" class="mb-0">
            <el-input v-model="lp_num" />
          </el-form-item>
          <el-form-item>
            <small class="text-muted">인식된 차번이 다른 경우 수정할 수 있습니다.</small>
          </el-form-item>
          <el-form-item label="거래처명">
            <el-select class="w-100" v-model="correspondent_id" placeholder="거래처명을 선택하십시오" @change="onCorrespondent">
              <el-option v-for="(item,index) in correspondent_list" :label="item.name" :value="item.id" :key="`correspondent-opts-${index}`" />
            </el-select>
          </el-form-item>
          <el-form-item label="산지">
            <el-select class="w-100" v-model="origin" placeholder="산지를 선택하십시오" :disabled="!correspondent_id">
              <el-option v-for="(item,index) in origin_list" :label="item.name" :value="item.name" :key="`origin-opts-${index}`" />
            </el-select>
          </el-form-item>
          <el-form-item label="골재종류">
            <el-select class="w-100" v-model="freight_id" placeholder="골재종류를 선택하십시오">
              <el-option v-for="(item,index) in freight_list" :label="item.name" :value="item.id" :key="`freight-opts-${index}`" />
            </el-select>
          </el-form-item>
          <el-form-item label="수량">
            <el-select class="w-100" v-model="quantity" placeholder="수량을 선택하십시오" :disabled="!correspondent_id">
              <!-- <el-option label="16㎥" :value="16" />
              <el-option label="16.5㎥" :value="16.5" />
              <el-option label="17㎥" :value="17" />
              <el-option label="17.5㎥" :value="17.5" />
              <el-option label="18㎥" :value="18" /> -->
              <el-option v-for="quantity_opt in correspondentQuantity" :key="`quantity-opts-${quantity_opt}`" :label="`${quantity_opt}㎥`" :value="quantity_opt" />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div v-else-if="!is_ready">
        입고 내역이 저장되었습니다. 감사합니다.
        <el-button class="float-right" type="primary" @click="onClose">닫기</el-button>
      </div>
    </CCardBody>
    <CCardFooter>
      <el-button class="float-right" type="primary" icon="el-icon-check" @click="submit" v-if="is_ready">저장</el-button>
    </CCardFooter>
  </CCard>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'DeliveryWearing',

  data() {
    return {
      is_ready: true,
      loading: false,

      factory: null,
      event_list: [],
      correspondent_list: [],
      origin_list: [],
      freight_list: [],

      event_id: null,
      event: null,
      lp_num: null,
      correspondent_id: null,
      origin: null,
      freight_id: null,
      quantity: null
    }
  },
  computed: {
    correspondentQuantity() {
      if (!this.correspondent_id) return [];
      const correspondent = this.correspondent_list.find(el => el.id === this.correspondent_id);
      return correspondent.quantity_contract;
    }
  },
  mounted() {
    this.getWearingBasic(this.$route.params.id);
  },
  methods: {
    getWearingBasic(id) {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        ipp: 10,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/delivery/wearing/?factory_id=${id}`)
        .then(response => {
          this.factory = {
            id: id,
            name: response.data.factory
          };
          this.freight_list = JSON.parse(JSON.stringify(response.data.freight_list));
          this.correspondent_list = JSON.parse(JSON.stringify(response.data.correspondent_list));
          this.event_list = JSON.parse(JSON.stringify(response.data.event_list));
        })
        .catch(error => {
          console.error(error);
        })
    },
    onCorrespondent() {
      let correspondent = this.correspondent_list.find(el => el.id === this.correspondent_id);
      this.origin_list = correspondent.origins;
    },
    onEvent() {
      this.event = this.event_list.find(el => el.id === this.event_id);
      this.lp_num = this.event.lp_num;
    },
    submit() {
      if (!this.lp_num) {
        this.$notify.warning({
          title: '확인',
          message: '차량 번호 입력을 확인하십시오.'
        });
        return;
      }
      // if (!this.correspondent_id) {
      //   this.$notify.warning({
      //     title: '확인',
      //     message: '업체 정보를 선택하십시오.'
      //   });
      //   return;
      // }
      // if (!this.origin) {
      //   this.$notify.warning({
      //     title: '확인',
      //     message: '산지 정보를 선택하십시오.'
      //   });
      //   return;
      // }
      if (!this.freight_id) {
        this.$notify.warning({
          title: '확인',
          message: '골재종류 정보를 선택하십시오.'
        });
        return;
      }
      if (!this.quantity) {
        this.$notify.warning({
          title: '확인',
          message: '수량을 선택하십시오.'
        });
        return;
      }
      let payloads = [{
        factory_id: this.$route.params.id,
        event_id: this.event.id,
        lp_num: this.lp_num,
        correspondent_id: this.correspondent_id,
        origin: this.origin,
        freight_id: this.freight_id,
        quantity: this.quantity
      }];
      this.$confirm('저장하시겠습니까?.', '확인', {
        confirmButtonText: '저장',
        cancelButtonText: '취소',
        type: 'warning',
        center: true
      }).then(() => {
        axios.post(`/api/delivery/wearing/`, payloads)
          .then(response => {
            this.$notify.success({
              title: '전송 완료',
              message: '입고 내역이 저장되었습니다.'
            });
            this.is_ready = false;
          })
          .catch(error => {
            console.error(error);
            this.$alert(
              '입고 내역을 전송할 수 없습니다. 관리자에게 문의하십시오.',
              '오류',
              {
                confirmButtonText: '확인'
              }
            )
          })
      })
    },
    onClose() {
      window.close();
    }
  }
}
</script>